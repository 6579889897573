import React, { useEffect } from 'react';
import { Modal, Form, message } from 'antd';
import ModalForm from '../forms/PropertyField.form';
// Hooks
import { useTranslation } from 'react-i18next';
// Interfaces
import { PropertyField } from '@/interfaces/propertyField.interface';
// Services
import APIService from '@/services/API';

export interface PropertyFieldsModalProps {
  visible: boolean;
  edit?: boolean;
  data?: Record<string, string | number>;
  onCancel?: () => void;
  onOk?: () => void;
}
const PropertyFieldsModal: React.FC<PropertyFieldsModalProps> = ({
  visible,
  edit,
  onCancel,
  onOk,
  data,
}) => {
  const [formRef] = Form.useForm<PropertyField>();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (edit && data) {
      formRef.setFieldsValue(data);
    }
  }, [edit, data, formRef]);

  const handleOnSubmit = async (values: PropertyField) => {
    setLoading(true);
    const response = data?._id
      ? await APIService.updatePropertyField(data?._id as string, values)
      : await APIService.createPropertyField(values);
    if (response.ok) {
      if (onOk) {
        onOk();
      }
      formRef.resetFields();
      if (edit) {
        message.success(t('general.updateSuccess'));
      } else {
        message.success(t('general.createSuccess'));
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      open={visible}
      title={edit ? t('general.edit') : t('general.create')}
      onOk={() => formRef.submit()}
      width={700}
      onCancel={() => {
        onCancel?.();
        formRef.resetFields();
      }}
      okButtonProps={{ loading }}
    >
      <ModalForm formRef={formRef} onSubmit={handleOnSubmit} />
    </Modal>
  );
};
export default PropertyFieldsModal;
