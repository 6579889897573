import React from 'react';
import { Form, FormInstance, Input, Row, Col, Tabs, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { PropertyField } from '@/interfaces/propertyField.interface';

export interface PropertyFieldFormProps {
  formRef: FormInstance<PropertyField>;
  onSubmit?: (values: PropertyField) => Promise<void>;
}

const PropertyFieldForm: React.FC<PropertyFieldFormProps> = ({ onSubmit, formRef }) => {
  const { t, i18n } = useTranslation();

  return (
    <Form
      form={formRef}
      name="property-field-form"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
      initialValues={{
        columns: 24,
        name: {
          en: '',
          es: '',
        },
      }}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Tabs
            size="small"
            tabPosition="left"
            defaultActiveKey={i18n.language}
            items={[
              {
                key: 'es',
                label: 'ES',
                forceRender: true,
                children: (
                  <Form.Item
                    label={t('units.name')}
                    name={['name', 'es']}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ),
              },
              {
                key: 'en',
                label: 'EN',
                forceRender: true,
                children: (
                  <Form.Item
                    label={t('units.name')}
                    name={['name', 'en']}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ),
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Form.Item label={t('general.description')} name="description">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={t('general.slug')}
            name="slug"
            rules={[{ required: true, message: <>{t('users.required')}</> }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={t('general.type')}
            name="type"
            rules={[{ required: true, message: <>{t('users.required')}</> }]}
          >
            <Select>
              <Select.Option value="text">{t('general.text')}</Select.Option>
              <Select.Option value="map">{t('general.map')}</Select.Option>
              <Select.Option value="textarea">{t('general.textArea')}</Select.Option>
              <Select.Option value="html">{t('general.html')}</Select.Option>
              <Select.Option value="number">{t('general.number')}</Select.Option>
              <Select.Option value="video">{t('general.video')}</Select.Option>
              <Select.Option value="attachment">{t('general.attachment')}</Select.Option>
              <Select.Option value="url">{t('general.url')}</Select.Option>
              <Select.Option value="area">{t('general.area')}</Select.Option>
              <Select.Option value="price">{t('general.price')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={t('general.group')}
            name="group"
            rules={[{ required: true, message: <>{t('users.required')}</> }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={6}>
          <Form.Item
            label={t('general.columns')}
            name="columns"
            initialValue={24}
            rules={[{ required: true, message: <>{t('users.required')}</> }]}
          >
            <Select>
              {[...Array(21)].map((_, index) => (
                <Select.Option value={index + 4} key={`col-${index + 4}`}>
                  {index + 4}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={6}>
          <Form.Item
            label={t('general.sort')}
            name="sort"
            initialValue={10}
            rules={[{ required: true, message: <>{t('users.required')}</> }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PropertyFieldForm;
